import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    interpolation: { escapeValue: false },
    resources: {
      en: {
        map: {
          CENTER: "Map center location",
          COORD: "Latitude/Longitude",
          TIME: "Sidereal time",
        },
        element: {
          AIRTMP: "Air temperature",
          PRCRIN_1HOUR: "Rainfall (1H)",
          PRCRIN_24HOUR: "Rainfall (24H)",
          RHUM: "Humidity",
          WNDSPD: "Wind speed",
          WNDDIR: "Wind direction",
        },
        details: {
          DATA_INFO: "Data information",
          LOC_INFO: "Location information",
          LOC_COORD: "Latitude/Longitude/Altitude",
          LOC_NAME: "Location name",
          OBS_INFO: "Observation information",
          OBS_TIME: "Observation time",
        },
      },
      ja: {
        map: {
          CENTER: "地図の中心位置",
          COORD: "緯度/経度",
          TIME: "恒星時",
        },
        element: {
          AIRTMP: "気温",
          PRCRIN_1HOUR: "1時間降水量",
          PRCRIN_24HOUR: "24時間降水量",
          RHUM: "湿度",
          WNDSPD: "風速",
          WNDDIR: "風向",
        },
        details: {
          DATA_INFO: "データの情報",
          LOC_INFO: "地点情報",
          LOC_COORD: "緯度/経度/高度",
          LOC_NAME: "地点名",
          OBS_INFO: "観測情報",
          OBS_TIME: "観測時刻",
        },
      },
    },
  });

export default i18n;
