import { ElementCode } from "./Element";

interface DataList {
  /** WNI TagID */
  tagId: string;
  /** WNI data_name */
  dataName: string;
  /** Country list expressed as list of country codes (ISO 3166-1 alpha-2) */
  countries: string[]; // list of ISO 3166-1 alpha-2
  /** Mapping of field keys */
  keyMap: FieldKeyMap;
}

interface FieldKeyMap {
  /** Location name field */
  LNAME: string;
  /** Observation time */
  obs_time: string;
  /** Element fields */
  elements: Record<ElementCode, string | [string, string]>;
}

export const dataList: DataList[] = [
  {
    tagId: "441000124",
    dataName: "KNMI_OBS_AWS_10MIN",
    countries: ["NL", "AW", "CW", "SX"],
    keyMap: {
      LNAME: "ENAME",
      obs_time: "announced_date",
      elements: {
        AIRTMP: "AIRTMP_1MIN_AVG",
        PRCRIN_1HOUR: "PRCRIN_1HOUR",
        PRCRIN_24HOUR: "PRCRIN_24HOUR",
        RHUM: "RHUM_1MIN_AVG",
        WNDSPD: ["WINDSPD_10MIN_AVG_10m", "WNDDIR_10MIN_AVG"],
      },
    },
  },
  {
    tagId: "441000117",
    dataName: "DAVIS_OBS_IT",
    countries: ["IT"],
    keyMap: {
      LNAME: "LNAME",
      obs_time: "OBSD",
      elements: {
        AIRTMP: "AIRTMP",
        PRCRIN_1HOUR: "PRCRIN_60MIN",
        PRCRIN_24HOUR: "PRCRIN_24HOUR",
        RHUM: "RHUM",
        // Since there are few points with values in WNDDIR that show 10-minute averages,
        // we use instantaneous values as a workaround.
        WNDSPD: ["WNDSPD_INST", "WNDDIR_INST"],
      },
    },
  },
  {
    tagId: "402200975",
    dataName: "DAVIS_OBS_TH",
    countries: ["TH"],
    keyMap: {
      LNAME: "LNAME",
      obs_time: "OBSD",
      elements: {
        AIRTMP: "AIRTMP",
        PRCRIN_1HOUR: "PRCRIN_60MIN",
        PRCRIN_24HOUR: "PRCRIN_24HOUR",
        RHUM: "RHUM",
        // Since there are few points with values in WNDDIR that show 10-minute averages,
        // we use instantaneous values as a workaround.
        WNDSPD: ["WNDSPD_INST", "WNDDIR_INST"],
      },
    },
  },
];
