export type ColorMap = (
  | string
  | (string | (string | string[])[])[]
  | (string | number | string[])[]
)[];

type LegendItem = [number | undefined, string];

export const parseColorMap = (colormap: ColorMap) => {
  const iter = colormap[Symbol.iterator]();
  let items: LegendItem[] = [];

  if (iter.next().value !== "case") {
    return null;
  }
  while (true) {
    const next = iter.next();
    if (next.done) {
      return items;
    }
    if (!Array.isArray(next.value)) {
      return null;
    }

    switch (next.value[0]) {
      case "==": {
        iter.next(); // skip
        break;
      }
      case "<": {
        const threshold = next.value[2];
        if (typeof threshold != "number") {
          return null;
        }
        const color = interpretColorSpec(iter.next().value);
        if (!color) {
          return null;
        }
        items.push([threshold, color]);
        break;
      }
      case "rgb": {
        const color = interpretColorSpec(next.value);
        if (!color) {
          return null;
        }
        items.push([undefined, color]);
        break;
      }
      default:
        break;
    }
  }
};

const interpretColorSpec = (spec: any): string | undefined => {
  if (Array.isArray(spec)) {
    if (spec[0] === "rgb") {
      return `rgb(${spec[1]}, ${spec[2]}, ${spec[3]})`;
    } else {
      return undefined;
    }
  }
  if (typeof spec == "string") {
    return spec;
  }
  return undefined;
};

export type ElementCode =
  | "AIRTMP"
  | "PRCRIN_1HOUR"
  | "PRCRIN_24HOUR"
  | "RHUM"
  | "WNDSPD";

export type ElementType = "normal" | "wind";

interface ElementInfo {
  /** Element type */
  type: ElementType;
  /** Element name */
  name: string;
  /** Element description */
  details: string;
  /** Unit */
  unit: string;
  /** Color mapping setting */
  colormap: ColorMap;
}

export const ElementConfig: Record<ElementCode, ElementInfo> = {
  AIRTMP: {
    type: "normal",
    name: "Air temperature",
    details: "Air temperature (1 min average)",
    unit: "°C",
    colormap: [
      "case",
      ["==", ["typeof", ["var", "value"]], "null"],
      ["rgba", 0, 0, 0, 0],
      ["<", ["var", "value"], -5],
      ["rgb", 0, 32, 128],
      ["<", ["var", "value"], 0],
      ["rgb", 0, 65, 255],
      ["<", ["var", "value"], 5],
      ["rgb", 0, 150, 255],
      ["<", ["var", "value"], 10],
      ["rgb", 185, 235, 255],
      ["<", ["var", "value"], 15],
      ["rgb", 255, 255, 240],
      ["<", ["var", "value"], 20],
      ["rgb", 255, 255, 150],
      ["<", ["var", "value"], 25],
      ["rgb", 250, 245, 0],
      ["<", ["var", "value"], 30],
      ["rgb", 255, 153, 0],
      ["<", ["var", "value"], 35],
      ["rgb", 255, 40, 0],
      /* others */ ["rgb", 180, 0, 104],
    ],
  },
  PRCRIN_1HOUR: {
    type: "normal",
    name: "Rainfall (1H)",
    details: "Rainfall (last 1 hour)",
    unit: "mm",
    colormap: [
      "case",
      ["==", ["typeof", ["var", "value"]], "null"],
      ["rgba", 0, 0, 0, 0],
      ["<", ["var", "value"], 1],
      ["rgb", 242, 242, 255],
      ["<", ["var", "value"], 5],
      ["rgb", 160, 210, 255],
      ["<", ["var", "value"], 10],
      ["rgb", 33, 140, 255],
      ["<", ["var", "value"], 20],
      ["rgb", 0, 65, 255],
      ["<", ["var", "value"], 30],
      ["rgb", 250, 245, 0],
      ["<", ["var", "value"], 50],
      ["rgb", 255, 153, 0],
      ["<", ["var", "value"], 80],
      ["rgb", 255, 40, 0],
      /* others */ ["rgb", 180, 0, 104],
    ],
  },
  PRCRIN_24HOUR: {
    type: "normal",
    name: "Rainfall (24H)",
    details: "Rainfall (last 24 hours)",
    unit: "mm",
    colormap: [
      "case",
      ["==", ["typeof", ["var", "value"]], "null"],
      ["rgba", 0, 0, 0, 0],
      ["<", ["var", "value"], 50],
      ["rgb", 242, 242, 255],
      ["<", ["var", "value"], 80],
      ["rgb", 160, 210, 255],
      ["<", ["var", "value"], 100],
      ["rgb", 33, 140, 255],
      ["<", ["var", "value"], 150],
      ["rgb", 0, 65, 255],
      ["<", ["var", "value"], 200],
      ["rgb", 250, 245, 0],
      ["<", ["var", "value"], 250],
      ["rgb", 255, 153, 0],
      ["<", ["var", "value"], 300],
      ["rgb", 255, 40, 0],
      /* others */ ["rgb", 180, 0, 104],
    ],
  },
  RHUM: {
    type: "normal",
    name: "Humidity",
    details: "Humidity (10 min average)",
    unit: "%",
    colormap: [
      "case",
      ["==", ["typeof", ["var", "value"]], "null"],
      ["rgba", 0, 0, 0, 0],
      ["<", ["var", "value"], 10],
      ["rgb", 84, 6, 0],
      ["<", ["var", "value"], 20],
      ["rgb", 118, 17, 0],
      ["<", ["var", "value"], 30],
      ["rgb", 171, 74, 1],
      ["<", ["var", "value"], 40],
      ["rgb", 231, 135, 7],
      ["<", ["var", "value"], 50],
      ["rgb", 255, 200, 70],
      ["<", ["var", "value"], 60],
      ["rgb", 255, 255, 240],
      ["<", ["var", "value"], 70],
      ["rgb", 128, 248, 231],
      ["<", ["var", "value"], 80],
      ["rgb", 31, 194, 211],
      ["<", ["var", "value"], 90],
      ["rgb", 0, 114, 154],
      ["<", ["var", "value"], 100],
      ["rgb", 0, 75, 150],
      /* others */ ["rgb", 1, 31, 125],
    ],
  },
  WNDSPD: {
    type: "wind",
    name: "Wind speed",
    unit: "m/s",
    details: "Wind speed (10 min average)",
    colormap: [
      "case",
      ["==", ["typeof", ["var", "value"]], "null"],
      ["rgba", 0, 0, 0, 0],
      ["<", ["var", "value"], 5],
      ["rgb", 242, 242, 255],
      ["<", ["var", "value"], 10],
      ["rgb", 0, 65, 255],
      ["<", ["var", "value"], 15],
      ["rgb", 250, 245, 0],
      ["<", ["var", "value"], 20],
      ["rgb", 255, 153, 0],
      ["<", ["var", "value"], 25],
      ["rgb", 255, 40, 0],
      /* others */ ["rgb", 180, 0, 104],
    ],
  },
};
