import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import "./App.css";
import "./i18n";
import windIcon from "./icons/wind.svg";
import noWindIcon from "./icons/no-wind.svg";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import { ColorMap, ElementCode, ElementConfig, parseColorMap } from "./Element";
import { dataList } from "./Data";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

interface Language {
  code: string;
  name: string;
}

const LANGUAGE_LIST: Language[] = [
  { code: "en", name: "English" },
  { code: "ja", name: "日本語" },
];

function App() {
  const [element, setElement] = useState<ElementCode>(
    Object.keys(ElementConfig)[0] as ElementCode
  );
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="selectors">
        <select
          id="languageSelector"
          name="languageSelector"
          value={i18n.language}
          onChange={(event) => {
            const language = event.currentTarget.value;
            i18n.changeLanguage(language);
          }}
        >
          {LANGUAGE_LIST.map((lang, _) => (
            <option key={lang.code} value={lang.code}>
              {lang.name}
            </option>
          ))}
        </select>
        <br />
        <select
          id="elementSelector"
          name="elementSelector"
          onChange={(event) => setElement(event.target.value as ElementCode)}
        >
          {Object.entries(ElementConfig).map(([code, info]) => (
            <option key={code} value={code}>
              {t(`element:${code}`)}
            </option>
          ))}
        </select>
      </div>
      <MapView element={element} />
    </>
  );
}

interface MapViewProps {
  element: ElementCode;
}

const MapView = (props: MapViewProps) => {
  const mapContainer = useRef(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [center, setCenter] = useState<[number, number] | undefined>(undefined);
  const [awsDetailsProps, setAwsDetailsProps] = useState<
    AwsDetailsProps | undefined
  >(undefined);
  const [checkedList, setCheckedList] = useState(dataList.map((_data) => true));
  const { t } = useTranslation();

  const setChecked = (tagId: string, checked: boolean) => {
    const list = [...checkedList];
    const index = dataList.map((data) => data.tagId).indexOf(tagId, 0);
    list.splice(index, 1, checked);
    setCheckedList(list);
  };

  const DEFAULT_ZOOM = 2;
  const MAX_ZOOM = 9;
  const DEFAULT_BOUNDS = "-180.0,-65.0,180.0,75.0".split(",").map(parseFloat);

  let hoveredId: undefined | string = undefined;

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current || "",
      style: "mapbox://styles/mapbox/outdoors-v12",
      zoom: DEFAULT_ZOOM,
      bounds: DEFAULT_BOUNDS as mapboxgl.LngLatBoundsLike,
      maxZoom: MAX_ZOOM,
      attributionControl: false,
    });
    map.current.addControl(new mapboxgl.AttributionControl(), "bottom-right");

    map.current.once("load", (_: any) => {
      if (map.current == null) {
        // unreachable
        return;
      }

      Promise.all(
        [
          [windIcon, "wind-icon"],
          [noWindIcon, "no-wind-icon"],
        ].map(([icon, name]) => rasterizeSvgIcon(icon, name, map.current))
      ).then((_) => {
        for (const dataListEntry of dataList) {
          const dataName = dataListEntry.dataName;
          const obsTimeField = dataListEntry.keyMap.obs_time;
          const elements = Object.keys(ElementConfig)
            .map((key) => {
              const mappedElement =
                dataListEntry.keyMap.elements[key as ElementCode];
              const result =
                typeof mappedElement == "string"
                  ? mappedElement
                  : mappedElement.join(",");
              return result;
            })
            .join(",");
          map.current.addSource(dataName, {
            type: "geojson",
            data: `https://pggdyt5wbl.execute-api.ap-northeast-1.amazonaws.com/test/${dataName}/getgeo?elements=ALT,${obsTimeField},${elements}`,
            generateId: true,
          });

          const mappedElement = dataListEntry.keyMap.elements[props.element];
          const coloredElement =
            typeof mappedElement == "string" ? mappedElement : mappedElement[0];
          const windDirectionValue =
            typeof mappedElement == "string" ? 0 : ["get", mappedElement[1]];
          map.current.addLayer({
            id: dataName,
            type: "circle",
            source: dataName,
            paint: {
              "circle-radius": 4,
              "circle-stroke-width": [
                "case",
                ["boolean", ["feature-state", "hover"], false],
                2,
                1,
              ],
              "circle-color": [
                "let",
                "value",
                ["get", coloredElement],
                ElementConfig[props.element].colormap,
              ],
              "circle-stroke-color": [
                "case",
                ["boolean", ["feature-state", "hover"], false],
                "#333333",
                "#888888",
              ],
            },
          });
          map.current.addLayer({
            id: getWindLayerName(dataName),
            type: "symbol",
            source: dataName,
            layout: {
              "icon-allow-overlap": true,
              "icon-image": [
                "case",
                ["==", ["typeof", windDirectionValue], "null"],
                "no-wind-icon",
                "wind-icon",
              ],
              "icon-ignore-placement": true,
              "icon-rotate": [
                "case",
                ["==", ["typeof", windDirectionValue], "null"],
                0,
                windDirectionValue,
              ],
              visibility: "none", // hardcoded as default element is not wind
            },
            paint: {
              "icon-color": [
                "let",
                "value",
                ["get", coloredElement],
                ElementConfig[props.element].colormap,
              ],
              "icon-halo-color": "rgba(128, 128, 128, 1)",
              "icon-halo-width": 5,
            },
          });
        }
      });
    });

    map.current.on("move", () => {
      if (!map.current) return;
      map.current.on("move", () => {
        const center = map.current.getCenter();
        setCenter([center.lat, center.lng]);
      });
    });

    for (const dataListEntry of dataList) {
      const dataName = dataListEntry.dataName;
      const windLayerName = getWindLayerName(dataName);
      const layers = [dataName, windLayerName];

      for (const layer of layers) {
        map.current.on("mouseenter", layer, (e: any) => {
          map.current.getCanvas().style.cursor = "pointer";

          const feature = e.features[0];
          const coordinates = feature.geometry.coordinates.slice();
          const fprops = feature.properties;
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          const elements = Object.keys(ElementConfig).reduce((acc, code) => {
            const mappedElement =
              dataListEntry.keyMap.elements[code as ElementCode];
            const values =
              typeof mappedElement == "string"
                ? fprops[mappedElement]
                : mappedElement.map((key) => fprops[key]);
            acc[code as ElementCode] = values;
            return acc;
          }, {} as Record<ElementCode, number>);

          const dprops = {
            tagId: dataListEntry.tagId,
            dataName: dataListEntry.dataName,
            name: fprops[dataListEntry.keyMap.LNAME],
            lclid: fprops.LCLID,
            coordinates: [
              coordinates[0] as number,
              coordinates[1] as number,
              fprops.ALT as number,
            ],
            obs_time: fprops[dataListEntry.keyMap.obs_time],
            elements: elements,
          };
          setAwsDetailsProps(dprops);
        });
      }

      map.current.on("mousemove", dataName, (e: any) => {
        if (e.features.length > 0) {
          if (hoveredId != null) {
            map.current.setFeatureState(
              { source: dataName, id: hoveredId },
              { hover: false }
            );
          }
          hoveredId = e.features[0].id;
          map.current.setFeatureState(
            {
              source: dataName,
              id: hoveredId,
            },
            { hover: true }
          );
        }
      });

      for (const layer of layers) {
        map.current.on("mouseleave", layer, () => {
          setAwsDetailsProps(undefined);

          if (hoveredId !== null) {
            map.current.setFeatureState(
              { source: dataName, id: hoveredId },
              { hover: false }
            );
          }
          hoveredId = undefined;
        });
      }
    }
  });

  useEffect(() => {
    for (const dataListEntry of dataList) {
      const dataName = dataListEntry.dataName;
      const windLayerName = getWindLayerName(dataName);

      const mappedElement = dataListEntry.keyMap.elements[props.element];
      const coloredElement =
        typeof mappedElement == "string" ? mappedElement : mappedElement[0];
      const windDirectionValue =
        typeof mappedElement == "string" ? 0 : ["get", mappedElement[1]];

      if (!map.current) return;

      const isWindDisplayed = ElementConfig[props.element].type == "wind";
      if (map.current.getLayer(dataName)) {
        makeLayerVisible(map.current, dataName, !isWindDisplayed);
      }
      if (map.current.getLayer(windLayerName)) {
        makeLayerVisible(map.current, windLayerName, isWindDisplayed);
      }

      if (map.current.getLayer(dataName)) {
        map.current.setPaintProperty(dataName, "circle-color", [
          "let",
          "value",
          ["get", coloredElement],
          ElementConfig[props.element].colormap,
        ]);
      }

      if (map.current.getLayer(windLayerName)) {
        map.current.setLayoutProperty(windLayerName, "icon-image", [
          "case",
          ["==", ["typeof", windDirectionValue], "null"],
          "no-wind-icon",
          "wind-icon",
        ]);
        map.current.setLayoutProperty(windLayerName, "icon-rotate", [
          "case",
          ["==", ["typeof", windDirectionValue], "null"],
          0,
          windDirectionValue,
        ]);
        map.current.setPaintProperty(windLayerName, "icon-color", [
          "let",
          "value",
          ["get", coloredElement],
          ElementConfig[props.element].colormap,
        ]);
        map.current.setPaintProperty(
          windLayerName,
          "icon-halo-color",
          "rgba(128, 128, 128, 1)"
        );
        map.current.setPaintProperty(windLayerName, "icon-halo-width", 5);
      }
    }
  }, [props.element]);

  useEffect(() => {
    if (map.current) {
      for (const { index, data: data } of dataList.map((data, index) => ({
        index,
        data: data,
      }))) {
        const layerId = data.dataName;
        if (map.current.getLayer(layerId)) {
          if (checkedList[index]) {
            map.current.setLayoutProperty(layerId, "visibility", "visible");
          } else {
            map.current.setLayoutProperty(layerId, "visibility", "none");
          }
        }
      }
    }
  }, [checkedList]);

  return (
    <div className="map-container">
      <div ref={mapContainer} className="map-body" />
      <div id="aws-details-container">
        <div id="aws-details">
          {awsDetailsProps ? (
            <AwsDetails {...(awsDetailsProps as AwsDetailsProps)} />
          ) : (
            <AwsDetails />
          )}
        </div>
      </div>
      <div id="map-center-info-box">
        <div className="title">{t("map:CENTER")}</div>
        <dl>
          <dt>{t("map:COORD")}</dt>
          <dd>
            {center ? center[0].toFixed(4) : "--"}
            &nbsp;/&nbsp;
            {center ? center[1].toFixed(4) : "--"}
          </dd>
          <dt>{t("map:TIME")}</dt>
          <dd>
            {center
              ? getSiderealTime(center[1], new Date()).toISOString()
              : "--"}
          </dd>
        </dl>
      </div>
      <div id="data-selector">
        {dataList.map((item, index) => (
          <div
            className="data-selector-item"
            key={item.tagId}
            data-data={item.tagId}
          >
            <input
              type="checkbox"
              id={item.tagId}
              name={item.tagId}
              checked={checkedList[index]}
              onChange={(event) =>
                setChecked(event.target.id, event.target.checked)
              }
            />
            <label htmlFor={item.tagId}>{item.dataName}</label>
          </div>
        ))}
      </div>
      <Legend
        colormap={ElementConfig[props.element].colormap}
        unit={ElementConfig[props.element].unit}
      />
    </div>
  );
};

interface LegendProps {
  colormap: ColorMap;
  unit: string;
}

const Legend = (props: LegendProps) => {
  const colormap = parseColorMap(props.colormap);
  if (!colormap) {
    return <div id="aws-legend"></div>;
  }
  return (
    <div id="aws-legend">
      <div className="unit">
        <div>{props.unit}</div>
      </div>
      <div className="container">
        {colormap.map(([threshold, color], index) => (
          <div key={index}>
            <span className="color" style={{ backgroundColor: color }}></span>
            <span className="threshold">{threshold}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

interface AwsDetailsProps {
  tagId?: string;
  dataName?: string;
  lclid?: string;
  name?: string;
  coordinates?: number[];
  obs_time?: string;
  elements?: Record<ElementCode, number | [number, number]>;
}

const AwsDetails = (props: AwsDetailsProps) => {
  const stringifyValue = (value: string | number | undefined) => {
    return value == null ? "--" : value;
  };

  const stringifyElementValue = (value: number | [number, number]) => {
    const isWind = typeof value == "object";
    if (isWind) {
      return `${stringifyValue(value[0])} (${t(
        "element:WNDDIR"
      )}: ${stringifyValue(value[1])})`;
    } else {
      return stringifyValue(value);
    }
  };

  const { t } = useTranslation();
  const name = stringifyValue(props?.name);
  return (
    <>
      <div className="title">{name}</div>
      <div className="section-title">{t("details:DATA_INFO")}</div>
      <dl>
        <dt>TagID</dt>
        <dd>{stringifyValue(props?.tagId)}</dd>
        <dt>data_name</dt>
        <dd>{stringifyValue(props?.dataName)}</dd>
      </dl>
      <div className="section-title">{t("details:LOC_INFO")}</div>
      <dl>
        <dt>LCLID</dt>
        <dd>{stringifyValue(props?.lclid)}</dd>
        <dt>{t("details:LOC_NAME")}</dt>
        <dd>{name}</dd>
        <dt>{t("details:LOC_COORD")}</dt>
        <dd>
          {`${stringifyValue(props.coordinates?.[1])} / ${stringifyValue(
            props.coordinates?.[0]
          )} / ${stringifyValue(props.coordinates?.[2])}`}
        </dd>
      </dl>
      <div className="section-title">{t("details:OBS_INFO")}</div>
      {props?.obs_time ? (
        <dl>
          <dt>{t("details:OBS_TIME")} (UTC)</dt>
          <dd>{props.obs_time}</dd>
          {Object.entries(props.elements ? props.elements : {}).map(
            ([code, value]) => (
              <>
                <dt>{t(`element:${code}`)}</dt>
                <dd>{stringifyElementValue(value)}</dd>
              </>
            )
          )}
        </dl>
      ) : (
        "--"
      )}
    </>
  );
};

const getSiderealTime = (longitude: number, date: Date) => {
  const hourOffset = longitude / 15;
  const localTimestamp = date.getTime() + hourOffset * 3600 * 1000;
  return new Date(localTimestamp);
};

const rasterizeSvgIcon = async (icon: string, name: string, map: any) => {
  return new Promise((resolve, reject) => {
    const customIcon = new Image(16, 16);
    customIcon.src = icon;
    customIcon.onload = () => {
      map.addImage(name, customIcon, {
        sdf: true,
      });
      resolve(null);
    };
  });
};

const getWindLayerName = (dataName: string) => {
  return `${dataName}-wind`;
};

const makeLayerVisible = (map: any, layerId: string, visible: boolean) => {
  const visibility = visible ? "visible" : "none";
  map.setLayoutProperty(layerId, "visibility", visibility);
};

export default App;
